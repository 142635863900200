import React, { Dispatch, SetStateAction } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { keyframes } from '@emotion/react';
import Lottie from 'lottie-react';

import { APP_LINKS } from '../utils/constants';
import { useVotingState } from '../providers/GameProvider/VotingProvider.hooks';

import logo from '../assets/images/intro/logo.svg';
import background from '../assets/images/intro/background.svg';
import x from '../assets/images/intro/social/x.svg';
import tg from '../assets/images/intro/social/tg.svg';
import cm from '../assets/images/intro/social/cm.svg';
import cg from '../assets/images/intro/social/cg.svg';
import rd from '../assets/images/intro/social/rd.svg';
import dex from '../assets/images/intro/social/dex.webp';
import social_front from '../assets/images/intro/social_front.svg';
import social_back from '../assets/images/intro/social_back.svg';
import maskot from '../assets/images/intro/maskot.svg';
import maskot_mob from '../assets/images/intro/maskot_mob.svg';
import memes from '../assets/images/intro/memes_4.webp';
import menu from '../assets/images/header/menu.svg';
import btn_decor_1 from '../assets/images/about/btn_decoration_1.svg';
import btn_decor_2 from '../assets/images/about/btn_decoration_2.svg';
import decor from '../assets/images/intro/rectangle_290.webp';
import bird_animation_desk from '../assets/json/JSON_Bird_desktop.json';
import bird_animation_mob from '../assets/json/JSON_Bird_mobile.json';

function Intro({
  setMenuOpen,
  setComingSoonOpen,
}: {
  setMenuOpen: (state: boolean) => void;
  setComingSoonOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const { currentSeasonSelectedMemeID, setVotingOpen, allMemes } = useVotingState();

  const socials = [
    { picture: x, name: 'Twitter', link: APP_LINKS.TWITTER },
    { picture: tg, name: 'Telegram', link: APP_LINKS.TELEGRAM },
    { picture: cm, name: 'CoinMarketCap', link: APP_LINKS.COIN_MARKET_CAP },
    { picture: cg, name: 'CoinGecko', link: APP_LINKS.COIN_GECKO },
    { picture: dex, name: 'DEXTools', link: APP_LINKS.DEX_TOOLS },
    { picture: rd, name: 'Raydium', link: APP_LINKS.RAYDIUM },
  ];

  const getKeyframes = (width: number) => keyframes`
from {
  background-position-x: 0;
}
to {
  background-position-x: -${width}px;
}
`;

  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #DCD8F3 0%, #D3DFF6 50.01%, #D4EBFB 100%)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: isMobile ? undefined : `url(${background})`,
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          sx={{
            maxWidth: '1256px',
            width: '100%',
            marginTop: isMobile ? '20px' : '60px',
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? '40px' : '60px',
          }}
        >
          {isMobile && (
            <Box
              sx={{
                position: 'absolute',
                top: '20px',
                right: '20px',
              }}
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <img src={menu} alt="menu"></img>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0 20px',

              '& img': {
                width: isMobile ? '177px' : '350px',
                height: isMobile ? '104px' : '206px',
              },
            }}
          >
            <Box>
              <img src={logo} alt="logo"></img>
            </Box>
            {!isMobile && (
              <Box
                sx={{
                  minWidth: '470px',
                  height: '100px',
                  background: `url(${social_front}), url(${social_back})`,
                  backgroundPosition: 'top right, bottom left',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    height: '100%',
                    paddingRight: '8px',
                    paddingTop: '10px',
                  }}
                >
                  {socials.map((social) => (
                    <Box
                      key={social.name}
                      onClick={() => {
                        if (social.link !== '') {
                          window.open(social.link, '_blank');
                        } else {
                          setComingSoonOpen(true);
                        }
                      }}
                    >
                      <img
                        style={{
                          cursor: 'pointer',
                          width: '74px',
                          height: '74px',
                        }}
                        src={social.picture}
                        alt={social.name}
                      ></img>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: isMobile ? 'flex' : 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              alignItems: 'stretch',
              gap: '20px',
              padding: '0 20px',
            }}
          >
            <Box sx={{ maxWidth: isMobile ? '100%' : '598px' }}>
              <Box
                sx={{
                  padding: isMobile ? undefined : '44px 40px',
                  borderRadius: '40px',
                  backgroundImage: isMobile ? undefined : `url(${decor})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      height: 'max-content',
                      fontFamily: 'Poppins400',
                      fontSize: '20px',
                    }}
                  >
                    $TMEME, the true meme coin king. Snag some to win $1000 worth in a season’s winning meme coin just
                    for your vote.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    '& button': {
                      background: 'white',
                      color: 'black',
                      border: 'none',
                      borderRadius: '50px',
                      fontFamily: 'Lilita400',
                      fontSize: isMobile ? '24px' : '28px',
                      textTransform: 'uppercase',
                      maxWidth: '100%',
                      width: isMobile ? 'calc(100% - 10px)' : '430px',
                      height: isMobile ? '78px' : '90px',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      boxShadow: '-10px 10px 0px 0px #BAC9ED',
                      marginLeft: '10px',
                      marginTop: isMobile ? '15px' : '20px',
                      marginBottom: isMobile ? '10px' : undefined,

                      '&:hover': {
                        boxShadow: '-10px 10px 0px 0px #7888DA',
                      },
                      '&:focus-visible': {
                        outline: 0,
                      },
                    },
                  }}
                >
                  <button
                    onClick={() => {
                      if (APP_LINKS.BUY_COIN !== '') {
                        window.open(APP_LINKS.BUY_COIN, '_blank');
                      } else {
                        setComingSoonOpen(true);
                      }
                    }}
                  >
                    BUY TOKEN
                  </button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: isMobile ? undefined : '40px 40px 80px',
                borderRadius: '40px',
                border: isMobile ? undefined : '4px solid #BAC9ED',
                width: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography
                  sx={{
                    height: 'max-content',
                    fontFamily: 'Poppins400',
                    fontSize: '20px',
                  }}
                >
                  Trending Solana meme coin throwdowns where winning votes rake in{' '}
                  <b
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    $1000 in the top coin
                  </b>
                  . Cage match of the century: $TREMP VS $OBEMA VS $BODEN!
                </Typography>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  width: isMobile ? 'calc(100% - 40px)' : '400px',

                  '& button': {
                    background: 'white',
                    color: 'black',
                    border: 'none',
                    borderRadius: '50px',
                    fontFamily: 'Lilita400',
                    fontSize: isMobile ? '24px' : '28px',
                    textTransform: 'uppercase',
                    maxWidth: '100%',
                    width: isMobile ? 'calc(100% - 10px)' : '392px',
                    height: isMobile ? '78px' : '90px',
                    marginTop: isMobile ? '15px' : '20px',
                    marginBottom: isMobile ? '42px' : undefined,
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    boxShadow: '-10px 10px 0px 0px #BAC9ED',
                    marginLeft: '10px',

                    zIndex: 2,
                    position: 'relative',

                    '&:hover': {
                      boxShadow: '-10px 10px 0px 0px #7888DA',
                    },
                    '&:focus-visible': {
                      outline: 0,
                    },
                  },
                }}
              >
                <button
                  onClick={() => {
                    setVotingOpen(true);
                  }}
                >
                  {currentSeasonSelectedMemeID ? 'View vote results' : 'Vote now'}
                </button>
                <img
                  style={{
                    height: isMobile ? '110px' : '',
                    position: 'absolute',
                    top: '20px',
                    right: isMobile ? '-40px' : '-50px',
                    zIndex: 1,
                  }}
                  src={btn_decor_1}
                  alt="decoration"
                ></img>
                <img
                  style={{
                    height: isMobile ? '110px' : '',
                    position: 'absolute',
                    top: '20px',
                    right: isMobile ? '-40px' : '-50px',
                    zIndex: 3,
                  }}
                  src={btn_decor_2}
                  alt="decoration"
                ></img>
              </Box>
              <Box
                sx={{
                  width: '164px',
                  height: '124px',
                  position: 'absolute',
                  top: isMobile ? '-106px' : '-116px',
                  right: isMobile ? '-20px' : undefined,
                }}
              >
                <Lottie animationData={isMobile ? bird_animation_mob : bird_animation_desk} loop={true} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: isMobile ? '40px' : '60px',
          paddingBottom: isMobile ? '60px' : '80px',
          justifyContent: 'center',
          display: 'flex',
          background: `url(${memes})`,
          // mobile size 50% of desktop
          backgroundSize: isMobile ? '755px 235px' : '1510px 470px',
          backgroundRepeat: 'repeat-x',
          animation: isMobile
            ? `${getKeyframes(2420)} 80s linear infinite`
            : `${getKeyframes(4840)} 80s linear infinite`,
        }}
      >
        <Box
          sx={{
            maxWidth: '1216px',
            width: '100%',
          }}
        >
          <img
            style={{
              transform: isMobile ? undefined : 'translateX(-120px)',
            }}
            src={isMobile ? maskot_mob : maskot}
            alt="maskot"
          ></img>
        </Box>
      </Box>
      {allMemes?.map((meme) => {
        return <img key={meme.memeDocumentID} width={0} src={meme.url} alt="preload"></img>;
      })}
    </Box>
  );
}

export default Intro;
