import React, { Dispatch, SetStateAction } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import { APP_LINKS } from '../utils/constants';

import background from '../assets/images/buyMemeNFT/background_2.webp';
import background_mob from '../assets/images/buyMemeNFT/background_mob_2.webp';

function BuyMemeNFT({ setComingSoonOpen }: { setComingSoonOpen: Dispatch<SetStateAction<boolean>> }) {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #F5F8D8 0%, #FAEFD7 50%, #F9E8D9 100%)',
        paddingTop: isMobile ? undefined : '80px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: isMobile ? undefined : `url(${background})`,
          backgroundSize: '1920px 894px',
          backgroundPosition: 'calc(50% + 60px) top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          sx={{
            maxWidth: '1256px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              padding: '0 20px',
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile ? '100%' : '1000px',
              }}
            >
              {isMobile && (
                <img
                  style={{
                    width: '100%',
                  }}
                  src={background_mob}
                  alt="background"
                ></img>
              )}
              <Typography
                sx={{
                  fontFamily: 'Lilita400',
                  fontSize: isMobile ? '64px' : '120px',
                  lineHeight: 'normal',
                  marginBottom: isMobile ? '30px' : '40px',
                  marginTop: isMobile ? undefined : '35px',
                  textAlign: isMobile ? 'center' : undefined,
                  marginRight: '20px',
                }}
              >
                {/* Buy meme NFT-s */}
                {/* Utility */}
                <br />
                {/* Beyond LOLs */}
                Any Meme
                <br /> Votes Could <br />
                Mean $1000
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins400',
                  fontSize: isMobile ? '20px' : '24px',
                  lineHeight: 'normal',
                  textAlign: isMobile ? 'center' : undefined,
                  maxWidth: isMobile ? '100%' : '598px',
                }}
              >
           Picture it: your go-to meme coin, the one you've been tracking like a hawk, or the one that's already fattened your wallet— could be your golden ticket to a share of $1000 pool in the winning meme coin.
                <br />
                <br />
                Simple as pie: cast your vote for the hottest meme coins, buy $TMEME, and grab your slice of the meme coin pie!
              </Typography>
              <Box
                sx={{
                  '& button': {
                    background: 'white',
                    color: 'black',
                    border: 'none',
                    borderRadius: '50px',
                    fontFamily: 'Lilita400',
                    fontSize: isMobile ? '24px' : '28px',
                    textTransform: 'uppercase',
                    maxWidth: '100%',
                    width: isMobile ? 'calc(100% - 10px)' : '382px',
                    height: isMobile ? '78px' : '90px',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    boxShadow: '-10px 10px 0px 0px #F5D8B7',
                    marginTop: isMobile ? '40px' : '50px',
                    marginLeft: '10px',
                    marginBottom: isMobile ? '60px' : '125px',
                    '&:hover': {
                      boxShadow: '-10px 10px 0px 0px #F0AA57',
                    },
                  },
                }}
              >
                <button
                  onClick={() => {
                    if (APP_LINKS.BUY_COIN !== '') {
                      window.open(APP_LINKS.BUY_COIN, '_blank');
                    } else {
                      setComingSoonOpen(true);
                    }
                  }}
                >
                  Buy now
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BuyMemeNFT;
