import React, { useLayoutEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useVotingState } from '../../../providers/GameProvider/VotingProvider.hooks';

function VotingResults() {
  const { convertedVotingResults } = useVotingState();
  const isMobile = useMediaQuery('(max-width:1000px)');
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  useLayoutEffect(() => {
    function updateSize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const getOpacity = (index: number) => {
    const rowDesktop = Math.trunc(index / 5);
    const mobileRowLength = (innerWidth - 46) / 84;
    const rowMobile = Math.trunc(index / Math.trunc(mobileRowLength));
    const row = isMobile ? rowMobile : rowDesktop;

    switch (row) {
      case 0: {
        return 1;
      }
      case 1: {
        return 0.75;
      }
      case 2: {
        return 0.5;
      }

      default: {
        return 0.25;
      }
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: isMobile ? '40px 0 60px' : '60px 0',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: isMobile ? '40px 6px' : '40px 30px',
          padding: '0 20px',
          maxWidth: '600px',
        }}
      >
        {convertedVotingResults
          .sort((a, b) => b.percentage - a.percentage)
          .map((meme, index) => (
            <Box
              key={meme.memeDocumentID}
              sx={{
                border: '2px solid black',
                borderRadius: '15px',
                fontFamily: 'Lilita400',
                fontSize: '20px',
                paddingBottom: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: 'white',
                position: 'relative',
                opacity: getOpacity(index),
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '-24px',
                  width: '43px',
                  height: '22px',
                  borderTopLeftRadius: '43px',
                  borderTopRightRadius: '43px',
                  background: '#A19FDF',
                  color: 'white',
                  fontFamily: 'Lilita400',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                {index + 1}
              </Box>
              <Box
                sx={{
                  borderRadius: '8px',
                  background: '#EFEFEF',
                  width: isMobile ? '64px' : '80px',
                  height: isMobile ? '64px' : '80px',
                  margin: '5px',
                  overflow: 'hidden',
                  '& img': {
                    width: '100%',
                  },
                }}
              >
                <img src={meme.url} alt="meme"></img>
              </Box>
              {meme.percentage}%
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default VotingResults;
