export const APP_LINKS = {
  TWITTER: 'https://twitter.com/topmeme_io',
  // DISCORD: 'https://discord.gg/vTCfQxKsS8', // not in use
  TELEGRAM: 'https://t.me/topmeme_channel',
  COIN_MARKET_CAP: '',
  COIN_GECKO: '',
  RAYDIUM: 'https://dexscreener.com/solana/egk3bvedhwwtgxhtdho4ibucaqqpofegqdg5dqmlk9fj',
  BUY_COIN: 'https://dexscreener.com/solana/egk3bvedhwwtgxhtdho4ibucaqqpofegqdg5dqmlk9fj',
  DEX_TOOLS: 'https://www.dextools.io/app/en/solana/pair-explorer/EGK3BVeDhWwtGXhtdho4iBucAQQPoFEGqDg5DQmLK9fJ?t=1711193636721',
  MAGIC_EDEN: 'https://magiceden.io/marketplace/tmemt'
};
