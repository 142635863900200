import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useVotingState } from '../../../providers/GameProvider/VotingProvider.hooks';

function Counter() {
  const { allMemes, votingMemes } = useVotingState();

  const width = useMemo(() => {
    if (allMemes && votingMemes) {
      const memesRemainder = allMemes.length - votingMemes.length;
      const completePercent = memesRemainder / (allMemes.length - 1);
      const res = 216 * completePercent + 12;
      return res;
    } else {
      return 12;
    }
  }, [allMemes, votingMemes]);

  return (
    <Box
      sx={{
        bottom: '60px',
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        marginBottom: '60px',
      }}
    >
      <Box
        sx={{
          width: '228px',
          height: '12px',
          background: '#BCD5F3',
          borderRadius: '12px',
        }}
      >
        <Box
          sx={{
            height: '12px',
            borderRadius: '12px',
            background: '#4A91E5',
            transition: 'all 0.5s ease',
            willChange: 'width',
            width: `${width}px`,
          }}
        ></Box>
      </Box>
    </Box>
  );
}

export default Counter;
