import { initializeApp } from '@firebase/app';
import { getFirestore } from '@firebase/firestore';

/**
 * Init Firebase App on Client-side
 * Read more about firebase:
 * @link https://firebase.google.com/docs/web/setup
 */
const firebaseConfig = {
    apiKey: "AIzaSyCep9ZQ7bz48JRbzNxnzU7kqu-2V546ZTo",
    authDomain: "topmeme-e55c7.firebaseapp.com",
    projectId: "topmeme-e55c7",
    storageBucket: "topmeme-e55c7.appspot.com",
    messagingSenderId: "838206239213",
    appId: "1:838206239213:web:0044c4c1d0b9ec0c7a37e4",
    measurementId: "G-8FH5DEWQ71"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDb = getFirestore(firebaseApp);

export { firebaseApp, firestoreDb };
