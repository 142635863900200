import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import { APP_LINKS } from '../utils/constants';

import back from '../assets/images/about/background_new.webp';
import shine from '../assets/images/about/shine.svg';
import btn_decor_1 from '../assets/images/about/btn_decoration_1.svg';
import btn_decor_2 from '../assets/images/about/btn_decoration_2.svg';
import thumb_up_top from '../assets/images/about/thumb_up_top.webp';
import thumb_up_bottom from '../assets/images/about/thumb_up_bottom.webp';
import season_decor from '../assets/images/about/season_decor.webp';
import season_decor_mob from '../assets/images/about/season_decor_mob.webp';
import { useVotingState } from '../providers/GameProvider/VotingProvider.hooks';

function About({ setComingSoonOpen }: { setComingSoonOpen: Dispatch<SetStateAction<boolean>> }) {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const isLess1200 = useMediaQuery('(max-width:1200px)');
  const { setVotingOpen, currentSeasonSelectedMemeID, seasonNumber, seasonDays, convertedVotingResults } =
    useVotingState();

  const top3Memes = useMemo(() => {
    return convertedVotingResults?.sort((a, b) => b.percentage - a.percentage).slice(0, 3) || [];
  }, [convertedVotingResults]);

  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #D4EBFB 0%, #CAF3EF 49.92%, #E0F4D6 100%)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: isMobile ? `url(${thumb_up_top})` : `url(${back})`,
          backgroundSize: isMobile ? '428px 301px' : '2200px 1500px',
          backgroundPosition: isMobile ? 'left top' : isLess1200 ? '-500px top' : 'center top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          sx={{
            maxWidth: '1216px',
            height: isMobile ? undefined : '1500px',
            width: '100%',
            padding: '0 20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lilita400',
              fontSize: isMobile ? '64px' : '120px',
              lineHeight: 'normal',
              marginTop: isMobile ? '301px' : '80px',
              marginBottom: isMobile ? '30px' : '40px',
              maxWidth: '598px',
            }}
          >
            Meme Coin Vote Pays in Crypto
          </Typography>
          <Typography
            sx={{
              height: 'max-content',
              fontFamily: 'Poppins400',
              fontSize: isMobile ? '20px' : '24px',
              maxWidth: isMobile ? '100%' : '600px',
            }}
          >
            Welcome to Top MEME: the arena where <b>meme coin titans clash.</b> This time it's a battle royal between
            the coolest meme coins on Solana. Throw down your vote in these rumbles, hold $TMEME coin and bag those $$$
            prizes. It's a lol-filled frenzy where your vote doesn't just count—it pays.
            <Box
              sx={{
                '& button': {
                  background: 'white',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50px',
                  fontFamily: 'Lilita400',
                  fontSize: isMobile ? '24px' : '28px',
                  textTransform: 'uppercase',
                  maxWidth: '100%',
                  width: isMobile ? 'calc(100% - 10px)' : '382px',
                  height: isMobile ? '78px' : '90px',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  boxShadow: '-10px 10px 0px 0px #BAC9ED',
                  marginLeft: '10px',
                  marginTop: isMobile ? '30px' : '50px',
                  marginBottom: isMobile ? '40px' : '60px',

                  '&:hover': {
                    boxShadow: '-10px 10px 0px 0px #7888DA',
                  },
                  '&:focus-visible': {
                    outline: 0,
                  },
                },
              }}
            >
              <button
                onClick={() => {
                  setVotingOpen(true);
                }}
              >
                VOTE NOW
              </button>
            </Box>
            Laugh, Vote, Win - Every 3 Days. We're flipping the script on rewards: winning mene coin prizes are in the
            game now. Gear up and remember: only the slick $TMEME holders can be crowned the meme coin kings and share
            $1000 in the winning meme coin.
          </Typography>
          <Box
            sx={{
              maxWidth: '431px',
              height: isMobile ? undefined : '393px',
              padding: isMobile ? '35px 25px' : '30px',
              borderRadius: '40px',
              backgroundImage: isMobile ? `url(${season_decor_mob})` : `url(${season_decor})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
              margin: isMobile ? 'auto' : undefined,
              marginTop: isMobile ? '40px' : '50px',
            }}
          >
            <Box
              sx={{
                borderRadius: '28px',
                background: '#5ACED8',
                width: 'max-content',
                padding: isMobile ? '12px 30px' : '16px 40px',
                fontFamily: 'Lilita400',
                color: '#fff',
                fontSize: isMobile ? '16px' : '20px',
                lineHeight: 'normal',
                textAlign: 'center',
              }}
            >
              Season {seasonNumber}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                '& div': {
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '& .title': {
                    fontFamily: 'Poppins400',
                    fontSize: isMobile ? '14px' : '14px',
                    lineHeight: 'normal',
                  },
                  '& .subTitle': {
                    fontFamily: 'Lilita400',
                    fontSize: isMobile ? '24px' : '28px',
                    lineHeight: 'normal',
                  },
                },
              }}
            >
              <Box>
                <Typography className="title">Season duration</Typography>
                {/* <Typography className="subTitle">{seasonWeeks} weeks</Typography> */}
                <Typography className="subTitle">{seasonDays} days</Typography>
              </Box>
              <Box
                sx={{
                  borderLeft: '1px solid #5ACED8',
                }}
              >
                <Typography className="title">Winnings places</Typography>
                <Typography className="subTitle">1 coin</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins400',
                  fontSize: isMobile ? '14px' : '14px',
                }}
              >
                Current leaders
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  height: '86px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: '5px',
                  marginTop: '5px',
                }}
              >
                {top3Memes?.map((meme) => (
                  <Box
                    key={meme.memeDocumentID}
                    sx={{
                      border: '2px solid black',
                      borderRadius: '10px',
                      fontFamily: 'Lilita400',
                      fontSize: '16px',
                      paddingBottom: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      background: 'white',
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '4px',
                        background: '#EFEFEF',
                        width: '50px',
                        height: '50px',
                        margin: '5px',
                        overflow: 'hidden',
                        '& img': {
                          width: '100%',
                        },
                      }}
                    >
                      <img src={meme.url} alt="meme"></img>
                    </Box>
                    {meme.percentage}%
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                '& button': {
                  background: 'white',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50px',
                  fontFamily: 'Lilita400',
                  fontSize: isMobile ? '24px' : '28px',
                  textTransform: 'uppercase',
                  width: 'calc(100% - 10px)',
                  height: isMobile ? '78px' : '90px',
                  marginTop: '10px',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  boxShadow: '-10px 10px 0px 0px #A8E2EB',
                  marginLeft: '10px',
                  zIndex: 2,
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: isMobile ? '10px' : '30px',
                  paddingLeft: isMobile ? '20px' : '40px',
                  paddingRight: isMobile ? '20px' : '40px',

                  '&:hover': {
                    boxShadow: '-10px 10px 0px 0px #67CBDB',
                  },
                  '&:focus-visible': {
                    outline: 0,
                  },
                },
              }}
            >
              <button
                onClick={() => {
                  setVotingOpen(true);
                }}
              >
                {currentSeasonSelectedMemeID ? 'View vote results' : 'Vote now'}
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
      {isMobile && (
        <Box
          sx={{
            marginTop: '30px',
            marginBottom: '60px',
            '& img': {
              width: '100%',
              height: '140px',
            },
          }}
        >
          <img src={thumb_up_bottom} alt="decoration"></img>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: isMobile? "130px" : '250px',
        }}
      >
        <Box
          sx={{
            maxWidth: '1216px',
            overflow: 'hidden',
            width: '100%',
        display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '0 20px',
            '& .shine': {
              width: isMobile ? '145%' : '100%',
              maxWidth: '982px',
            },
          }}
        >
          <img className="shine" src={shine} alt="shine"></img>
          <Typography
            sx={{
              fontFamily: 'Lilita400',
              fontSize: isMobile ? '44px' : '80px',
              lineHeight: 'normal',
              textAlign: 'center',
              marginTop: '-15px',
              marginBottom: isMobile ? '30px' : '40px',
            }}
          >
            $TMEME Turns Meme Dreams into Creamy Crypto Profits
          </Typography>
          <Typography
            sx={{
              height: 'max-content',
              fontFamily: 'Poppins400',
              textAlign: 'center',
              fontSize: isMobile ? '20px' : '24px',
              maxWidth: '805px',
            }}
          >
            The ultimate meme coin voting seasons are on, where your vote doesn't just count—it could earn you some meme
            coin gold. Attention: <b>only those who've bagged $TMEME during the voting season</b> qualify to snag a
            slice of the $1000 treasure. So, you wanna play and win big? Make sure $TMEME's in your arsenal before you
            hit that vote button.
          </Typography>
          <Box
            sx={{
              position: 'relative',
              width: isMobile ? '100%' : undefined,

              '& button': {
                background: 'white',
                color: 'black',
                border: 'none',
                borderRadius: '50px',
                fontFamily: 'Lilita400',
                fontSize: isMobile ? '24px' : '28px',
                textTransform: 'uppercase',
                maxWidth: '100%',
                width: isMobile ? 'calc(100% - 10px)' : '392px',
                height: isMobile ? '78px' : '90px',
                margin: isMobile ? '30px 0 70px' : '50px 0 90px',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                boxShadow: '-10px 10px 0px 0px #B3E1C6',
                marginLeft: '10px',
                zIndex: 2,
                position: 'relative',

                '&:hover': {
                  boxShadow: '-10px 10px 0px 0px #30CDA6',
                },
                '&:focus-visible': {
                  outline: 0,
                },
              },
            }}
          >
            <button
              onClick={() => {
                if (APP_LINKS.BUY_COIN !== '') {
                  window.open(APP_LINKS.BUY_COIN, '_blank');
                } else {
                  setComingSoonOpen(true);
                }
              }}
            >
              Buy now
            </button>
            {!isMobile && (
              <>
                <img
                  style={{
                    position: 'absolute',
                    top: '50px',
                    right: '-50px',
                    zIndex: 1,
                  }}
                  src={btn_decor_1}
                  alt="decoration"
                ></img>
                <img
                  style={{
                    position: 'absolute',
                    top: '50px',
                    right: '-50px',
                    zIndex: 3,
                  }}
                  src={btn_decor_2}
                  alt="decoration"
                ></img>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
