import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import paper from '../assets/images/roadmap/toilet_paper.webp';
import paper_mob from '../assets/images/roadmap/toilet_paper_mob.webp';
import back_decor from '../assets/images/roadmap/back_decor.svg';

function Roadmap() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #F9E8D9 0%, #ECD8CF 49.48%, #DDC9C6 100%)',
        position: 'relative',
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <img
              style={{
                height: '270px',
                width: '393px',
                position: 'relative',
                zIndex: 9,
              }}
              src={paper_mob}
              alt="paper"
            ></img>
            <Box
              sx={{
                height: '15px',
                width: 'calc(100vw - 380px)',
                background: 'black',
                position: 'absolute',
                right: 0,
                top: '78px',
              }}
            ></Box>
            <Typography
              sx={{
                fontFamily: 'Lilita400',
                fontSize: '64px',
                lineHeight: 'normal',
                marginLeft: '54px',
                position: 'absolute',
              }}
            >
              Roadmap
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
            }}
          >
            <Box
              sx={{
                minWidth: '16px',
                background: 'black',
                marginLeft: '19px',
              }}
            ></Box>
            <Box
              sx={{
                padding: '0 20px',
              }}
            >
              <Box
                sx={{
                  marginBottom: '60px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '10px',
                  '& p:not(.title)': {
                    marginLeft: '40px',
                    position: 'relative',
                    fontFamily: 'Poppins400',
                    fontSize: '20px',
                    lineHeight: 'normal',

                    '&::before': {
                      background: '#CEA18D',
                      position: 'absolute',
                      content: '""',
                      display: 'block',
                      width: '20px',
                      height: '2px',
                      top: 'calc(50%)',
                      left: '-40px',
                    },
                  },
                  '& .title': {
                    fontFamily: 'Lilita400',
                    fontSize: '28px',
                    lineHeight: 'normal',
                    marginBottom: '20px',
                    marginTop: '40px',
                  },
                }}
              >
                <Typography className="title">Stage 1</Typography>

                <Typography>Blastoff! 🚀</Typography>
                <Typography>Get listed on CoinGecko/ Coinmarketcap</Typography>
                <Typography>Reach 1,000+ Hodlers</Typography>
                <Typography>Unleash the power of memes to make trend!</Typography>

                <Typography className="title">Stage 2</Typography>

                <Typography>10.000+ holders</Typography>
                <Typography>CEX listings</Typography>
                <Typography>New team members!</Typography>

                <Typography className="title">Stage 3</Typography>

                <Typography>100,000+ holders</Typography>
                <Typography>Top Meme becomes truly legendary</Typography>
                <Typography>Top Meme foundation</Typography>
                <Typography>Top Meme community</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              width: 'calc((100vw - 1256px) / 2)',
              position: 'absolute',
              top: '136px',
            }}
          >
            <Box
              sx={{
                height: '30px',
                background: 'black',
              }}
            ></Box>
            <Box
              sx={{
                height: '30px',
                background: '#CEA18D',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                maxWidth: '1256px',
                width: '100%',
                backgroundImage: `url(${paper}), url(${back_decor})`,
                backgroundSize: '455px 1400px, 1217px 1226px',
                backgroundPosition: 'left top, center 254px',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Box
                sx={{
                  padding: '0 20px',
                }}
              >
                <Box
                  sx={{
                    marginLeft: '515px',
                    '@media(max-width: 1150px)': {
                      marginLeft: '450px',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Lilita400',
                      fontSize: '120px',
                      lineHeight: 'normal',
                      marginBottom: '130px',
                      marginTop: '80px',
                    }}
                  >
                    Roadmap
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '173px',
                      '& .wrapper': {
                        height: '320px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '15px',
                        '& p': {
                          marginLeft: '50px',
                          position: 'relative',
                          fontFamily: 'Poppins400',
                          fontSize: '24px',
                          lineHeight: 'normal',

                          '&::before': {
                            background: '#CEA18D',
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            width: '30px',
                            height: '2px',
                            top: 'calc(50%)',
                            left: '-50px',
                          },
                        },
                      },
                    }}
                  >
                    <Box className="wrapper">
                      <Typography>Blastoff! 🚀</Typography>
                      <Typography>Get listed on CoinGecko/Coinmarketcap</Typography>
                      <Typography>Reach 1,000+ Hodlers</Typography>
                      <Typography>Unleash the power of memes to make trend!</Typography>
                    </Box>
                    <Box className="wrapper">
                      <Typography>10.000+ holders</Typography>
                      <Typography>CEX listings</Typography>
                      <Typography>New team members!</Typography>
                    </Box>
                    <Box className="wrapper">
                      <Typography>100,000+ holders</Typography>
                      <Typography>Top Meme becomes truly legendary</Typography>
                      <Typography>Top Meme foundation</Typography>
                      <Typography>Top Meme community</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Roadmap;
