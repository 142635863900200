import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import { APP_LINKS } from '../utils/constants';

import x from '../assets/images/footer/x.svg';
import tg from '../assets/images/footer/tg.svg';
import decor from '../assets/images/footer/decor.svg';
import background from '../assets/images/footer/background.svg';
import background_mob from '../assets/images/footer/background_mob.svg';

function Footer() {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const isLess1250 = useMediaQuery('(max-width:1250px)');

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#352011',
          backgroundImage: isMobile ? undefined : `url(${background})`,
          backgroundPosition: isLess1250 ? '-1180px bottom' : 'center bottom',
          backgroundSize: '3600px 932px',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height: '36px',
            width: '100%',
            backgroundImage: `url(${decor})`,
            backgroundPosition: 'center',
          }}
        ></Box>
        <Box
          sx={{
            maxWidth: '1256px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              padding: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Lilita400',
                fontSize: isMobile ? '64px' : '120px',
                lineHeight: 'normal',
                color: 'white',
                marginTop: isMobile ? '60px' : '124px',
                textAlign: isMobile ? 'center' : undefined,
              }}
            >
              Join the <br />
              community
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins400',
                fontSize: isMobile ? '20px' : '24px',
                lineHeight: 'normal',
                color: 'white',
                marginTop: isMobile ? '30px' : '40px',
                marginBottom: isMobile ? '30px' : '50px',
                textAlign: isMobile ? 'center' : undefined,
                maxWidth: isMobile ? '100%' : '495px',
                '& a': {
                  color: 'white',
                },
              }}
            >
              Join us and stay connected with exclusive content and updates in our communities!
              <br />
              <br />
              <a href="mailto:topmeme@topmeme.io">topmeme@topmeme.io</a>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                marginBottom: isMobile ? '30px' : '160px',
                justifyContent: isMobile ? 'center' : undefined,
                '& div': {
                  width: isMobile ? '100px' : '140px',
                  height: isMobile ? '100px' : '140px',
                  borderRadius: '50%',
                  background: 'white',
                  display: 'flex',
                  cursor: 'pointer',
                },
              }}
            >
              <Box onClick={() => window.open(APP_LINKS.TWITTER, '_blank')}>
                <img src={x} alt={'twitter'}></img>
              </Box>
              <Box onClick={() => window.open(APP_LINKS.TELEGRAM, '_blank')}>
                <img src={tg} alt={'telegram'}></img>
              </Box>
            </Box>
          </Box>
          {isMobile && (
            <Box
              sx={{
                '& img': {
                  width: '100%',
                  marginBottom: '-4px',
                },
              }}
            >
              <img src={background_mob} alt="decor"></img>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
