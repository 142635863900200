import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import divider from '../assets/images/howToBuy/divider.svg';
import divider_horizontal from '../assets/images/howToBuy/divider_horizontal.svg';
import character from '../assets/images/howToBuy/character.svg';
import character_decor from '../assets/images/howToBuy/character_decor.svg';
import character_mob from '../assets/images/howToBuy/character_mob.svg';
import character_decor_mob from '../assets/images/howToBuy/character_decor_mob.svg';
import arrow_right from '../assets/images/howToBuy/arrow_right.svg';
import arrow_left from '../assets/images/howToBuy/arrow_left.svg';

import 'swiper/css';

function Card({ number, title, subTitle }: { number: number; title: string; subTitle: string }) {
  const [image, setImage] = useState('');

  useEffect(() => {
    const loadImage = async () => {
      const img = await import(`../assets/images/howToBuy/${number}.svg`);
      setImage(img.default);
    };

    loadImage();
  }, [number]);

  return (
    <Box
      sx={{
        background: 'white',
        borderTopLeftRadius: number === 1 ? '40px' : undefined,
        borderBottomLeftRadius: number === 1 ? '40px' : undefined,
        borderTopRightRadius: number === 4 ? '40px' : undefined,
        borderBottomRightRadius: number === 4 ? '40px' : undefined,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '100%',
      }}
    >
      {number !== 1 && <img src={divider} alt="divider"></img>}

      <Box
        sx={{
          padding: '80px 60px',
          display: 'flex',
          gap: '40px',
        }}
      >
        <img width="140px" height="211px" src={image} alt="number"></img>
        <Box>
          <Typography
            sx={{
              fontFamily: 'Lilita400',
              fontSize: '40px',
              lineHeight: 'normal',
              marginBottom: '30px',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins400',
              fontSize: '20px',
              lineHeight: 'normal',
            }}
          >
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function HowToBuy() {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const isLess1530 = useMediaQuery('(max-width:1530px)');
  const STEPS = [
    {
      title: 'Create a wallet',
      subTitle: `Grab Phantom, Solflare or any other Solana-friendly wallet from the App Store or Google Play Store at no cost. If you're on a desktop, snag the Google Chrome extension by visiting phantom.app. It's all free, so dive in!`,
    },
    {
      title: 'Some SOL needed',
      subTitle: `Ensure there's some SOL on your balance to make the switch to $TMEME. If your SPL wallet is running low, you can buy directly on Phantom, transfer from another wallet, or make a purchase on a different exchange & send it to your wallet.`,
    },
    {
      title: 'Soon: DEX & CES',
      subTitle: `Get ready to ride the TMEME wave on both DEX and CEX real soon. You'll be able to choose your battlefield, grab that TMEME in the slickest way possible.`,
    },
    {
      title: 'Prep for the takeover!',
      subTitle: `While the REAL TMEME is gearing up for launch, scams be lurking. Stay sharp, stay smart – the authentic crypto meme experience is on the horizon!`,
    },
  ];
  const swiperRef = useRef<any>(null);
  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #E0F4D6 -13.03%, #EBF6D6 50%, #F5F8D8 113.03%)',
        overflow: 'hidden',
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            padding: '0 20px',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              minHeight: isMobile ? '288px' : '268px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Lilita400',
                fontSize: '64px',
                lineHeight: 'normal',
              }}
            >
              How to buy
            </Typography>
            <Typography
              sx={{
                marginTop: '20px',
                fontFamily: 'Poppins400',
                fontSize: '20px',
                lineHeight: 'normal',
                maxWidth: '220px',
              }}
            >
              <b>$TMEME</b> token is traded on Raydium. More exchanges in progress...
            </Typography>
            <img
              style={{
                position: 'absolute',
                top: '40px',
                right: '-30px',
              }}
              src={character_mob}
              alt="character"
            ></img>
            <img
              style={{
                position: 'absolute',
                top: '40px',
                right: '-30px',
                zIndex: 9,
              }}
              src={character_decor_mob}
              alt="character decor"
            ></img>
          </Box>
          <Box
            sx={{
              background: 'white',
              borderRadius: '40px',
              marginTop: '-56px',
              position: 'relative',
              padding: '40px 20px',
              marginBottom: '60px',
            }}
          >
            {STEPS.map((step, index) => (
              <Box key={step.title}>
                {index !== 0 && (
                  <img
                    style={{
                      margin: '30px 0px',
                      width: '100%',
                    }}
                    src={divider_horizontal}
                    alt="divider"
                  ></img>
                )}

                <Typography
                  sx={{
                    fontFamily: 'Lilita400',
                    fontSize: '28px',
                    lineHeight: 'normal',
                    marginBottom: '20px',
                  }}
                >
                  <span
                    style={{
                      color: '#30CDA6',
                    }}
                  >
                    {index + 1}.{' '}
                  </span>
                  {step.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins400',
                    fontSize: '20px',
                    lineHeight: 'normal',
                  }}
                >
                  {step.subTitle}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '80px',
            marginBottom: '80px',
          }}
        >
          <Box
            sx={{
              maxWidth: '1256px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                padding: '0 20px',
                '& .swiper': {
                  overflow: 'visible',
                },
                '& .swiper-slide': {
                  height: 'auto',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '30px',
                }}
              >
                <img
                  style={{
                    marginBottom: '-118px',
                    marginRight: isLess1530 ? undefined : '-118px',
                    transform: isLess1530 ? undefined : 'translateX(-128px)',
                  }}
                  src={character}
                  alt="character"
                ></img>
                <img
                  style={{
                    position: 'absolute',
                    zIndex: 9,
                    transform: isLess1530 ? undefined : 'translateX(-128px)',
                  }}
                  src={character_decor}
                  alt="character decor"
                ></img>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    flexDirection: 'column',

                    '& button': {
                      width: '90px',
                      height: '90px',
                      borderRadius: '50%',
                      border: '2px solid #B3E1C6',
                      background: 'transparent',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        cursor: 'pointer',
                        border: '2px solid #30CDA6',
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Lilita400',
                        fontSize: '120px',
                        lineHeight: 'normal',
                      }}
                    >
                      How to buy
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        marginTop: '20px',
                      }}
                    >
                      <button onClick={() => swiperRef.current.slidePrev()}>
                        <img src={arrow_left} alt="arrow"></img>
                      </button>
                      <button onClick={() => swiperRef.current.slideNext()}>
                        <img src={arrow_right} alt="arrow"></img>
                      </button>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins400',
                        fontSize: '20px',
                        lineHeight: 'normal',
                      }}
                    >
                      <b>$TMEME</b> token is traded on Raydium. More exchanges in progress...
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Swiper
                slidesPerView={1.5}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper: any) => {
                  swiperRef.current = swiper;
                }}
              >
                {STEPS.map((step, index) => (
                  <SwiperSlide key={step.title}>
                    <Card number={index + 1} title={step.title} subTitle={step.subTitle}></Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default HowToBuy;
