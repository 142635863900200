import React, { useState } from 'react';

import Intro from './components/Intro';
import Header from './components/Header';
import About from './components/About';
import HowToBuy from './components/HowToBuy';
import BuyMemeNFT from './components/BuyMemeNFT';
import Roadmap from './components/Roadmap';
import Tokenomics from './components/Tokenomics';
import Footer from './components/Footer';
import ComingSoon from './components/ComingSoon';
// import VotingAnnounce from './components/Voting';
import Voting from './components/Voting/Voting';
import { VotingProvider } from './providers/GameProvider/VotingProvider';

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isComingSoonOpen, setComingSoonOpen] = useState(false);

  return (
    <>
      <VotingProvider config={{}}>
        <Header setComingSoonOpen={setComingSoonOpen} setMenuOpen={setMenuOpen} isMenuOpen={isMenuOpen}></Header>
        <Intro setComingSoonOpen={setComingSoonOpen} setMenuOpen={setMenuOpen}></Intro>
        <About setComingSoonOpen={setComingSoonOpen}></About>
        <HowToBuy></HowToBuy>
        <BuyMemeNFT setComingSoonOpen={setComingSoonOpen}></BuyMemeNFT>
        <Roadmap></Roadmap>
        <Tokenomics></Tokenomics>
        <Footer></Footer>

        {/* <VotingAnnounce setComingSoonOpen={setComingSoonOpen}></VotingAnnounce> */}
        <Voting setComingSoonOpen={setComingSoonOpen}></Voting>
        <ComingSoon isComingSoonOpen={isComingSoonOpen} setComingSoonOpen={setComingSoonOpen}></ComingSoon>
      </VotingProvider>
    </>
  );
}

export default App;
