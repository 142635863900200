import React, { useMemo } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import clock from '../../../assets/images/voting/clock.svg';
import decor from '../../../assets/images/voting/counter_decor.svg';
import { useVotingState } from '../../../providers/GameProvider/VotingProvider.hooks';

interface IVotingTimeLeftProps {
  showResults: boolean;
}

function VotingTimeLeft({ showResults }: IVotingTimeLeftProps) {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const { timeLeft } = useVotingState();

  const timeLeftString = useMemo(() => {
    let result = '';
    if (timeLeft) {
      if (timeLeft.months && timeLeft.months > 0) {
        result = result.concat(` ${timeLeft.months}m`);
      }
      if (timeLeft.days && timeLeft.days > 0) {
        result = result.concat(` ${timeLeft.days}d`);
      }
      if (timeLeft.hours && timeLeft.hours > 0) {
        result = result.concat(` ${timeLeft.hours}h`);
      }
      if (timeLeft.minutes && timeLeft.minutes > 0) {
        result = result.concat(` ${timeLeft.minutes}m`);
      }
      if (timeLeft.seconds && timeLeft.seconds > 0) {
        result = result.concat(` ${timeLeft.seconds}s`);
      }
    }
    return result || '---';
  }, [timeLeft]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '60px',
      }}
    >
      {isMobile && !showResults && (
        <Box
          sx={{
            marginBottom: '40px',
          }}
        >
          <img src={decor} alt="decor"></img>
        </Box>
      )}
      <img width={'52px'} src={clock} alt="clock"></img>
      <Typography
        sx={{
          fontFamily: 'Poppins400',
          fontSize: isMobile ? '20px' : '20px',
          lineHeight: 'normal',
          textAlign: 'center',
          margin: '0 20px',
        }}
      >
        Time left until the end of voting
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Lilita400',
          fontSize: isMobile ? '24px' : '28px',
          lineHeight: 'normal',
          textAlign: 'center',
        }}
      >
        {timeLeftString}
      </Typography>
    </Box>
  );
}

export default VotingTimeLeft;
