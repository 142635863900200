import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import carrot from '../assets/images/tokenomics/carrot.svg';
import carrot_mob from '../assets/images/tokenomics/carrot_mob.svg';
import back_decor from '../assets/images/tokenomics/background_decor.svg';
import top_decor from '../assets/images/tokenomics/top_decor.svg';

function Tokenomics() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        position: 'relative',
        background: '#4A2E1A',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              height: 'calc(100vw / 3)',
              display: 'flex',
              alignItems: 'stretch',
              background: '#DDC9C6',
              width: '100%',
              top: 0,
            }}
          >
            <Box
              sx={{
                minWidth: '16px',
                background: 'black',
                marginLeft: '19px',
              }}
            ></Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '276px',
              background: '#DDC9C6',
              backgroundRepeat: 'repeat-x',
              backgroundImage: `url(${top_decor})`,
              backgroundPosition: 'bottom',
            }}
          ></Box>
        )}

        <Box
          sx={{
            width: '100%',
            backgroundImage: isMobile ? undefined : `url(${back_decor})`,
            backgroundSize: '1920px 800px',
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {isMobile && (
            <img
              style={{
                width: '100%',
                marginTop: 'calc((-100vw / 3.7))',
              }}
              src={carrot_mob}
              alt="carrot"
            ></img>
          )}
          <Box
            sx={{
              padding: '0 20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!isMobile && (
              <img
                style={{
                  maxWidth: '804px',
                  width: '100%',
                  marginTop: '-190px',
                }}
                src={carrot}
                alt="carrot"
              ></img>
            )}
            <Typography
              sx={{
                fontFamily: 'Lilita400',
                fontSize: isMobile ? '64px' : '120px',
                lineHeight: 'normal',
                color: 'white',
                marginTop: isMobile ? undefined : '40px',
              }}
            >
              Tokenomics
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Lilita400',
                fontSize: isMobile ? '28px' : '40px',
                lineHeight: 'normal',
                color: 'white',
                marginTop: isMobile ? '20px' : '30px',
              }}
            >
              Total Supply
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Lilita400',
                fontSize: isMobile ? '28px' : '80px',
                lineHeight: 'normal',
                color: '#FB0',
              }}
            >
              100.000.000.000.000
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins400',
                fontSize: isMobile ? '20px' : '24px',
                lineHeight: 'normal',
                marginTop: isMobile ? '30px' : '40px',
                marginBottom: isMobile ? '60px' : '160px',
                color: 'white',
                textAlign: 'center',
              }}
            >
              Top Meme - No Fuss, All Trust. We're transparent! 🚀
              <br />A gigantic 70% of tokens are in the liquidity pool, LP tokens? Burnt! Contract? Booted! We stand for
              decentralization.
              <br />
              10% are reserved for CEX listings
              <br />
              10% goes to Community & Marketing
              <br />
              10% is going to be distributed among active Solana contributors (developers, NFT creators, etc.)
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Tokenomics;
