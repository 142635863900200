import { Box, Typography } from '@mui/material';
import decor from '../../../assets/images/voting/buy_nft_decor.webp';
import magicEden from '../../../assets/images/voting/magic_eden.svg';

interface IBuyNftBlockProps {
  onClick: () => void;
  isMobile: boolean;
}

function BuyNftBlock({ onClick, isMobile }: IBuyNftBlockProps) {
  return (
    <Box sx={{ margin: '0 auto' }}>
      <Box
        sx={{
          maxWidth: '598px',
          boxSizing: 'border-box',
          margin: '0 20px 0',
          padding: !isMobile ? '40px 52px 50px' : '30px 18px 30px',
          flex: '1 0 100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          backgroundImage: `url(${decor})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          marginBottom: '60px',
          '& button': {
            background: 'white',
            color: 'black',
            border: 'none',
            borderRadius: '50px',
            fontFamily: 'Lilita400',
            fontSize: isMobile ? '24px' : '28px',
            textTransform: 'uppercase',
            maxWidth: '100%',
            width: isMobile ? 'calc(100% - 10px)' : '392px',
            height: isMobile ? '78px' : '90px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            boxShadow: '-10px 10px 0px 0px #BAC9ED',
            marginLeft: '10px',

            zIndex: 2,
            position: 'relative',

            '&:hover': {
              boxShadow: '-10px 10px 0px 0px #7888DA',
            },
            '&:focus-visible': {
              outline: 0,
            },
          },
        }}
      >
        <img src={magicEden} alt="Magic Eden" />

        <Typography
          sx={{
            fontFamily: 'Poppins400',
            fontSize: '20px',
            lineHeight: '30px',
            textAlign: 'center',
            padding: '0 20px',
          }}
        >
          Buy the latest NFT collections of Top Memes directly from the Magic
          Eden marketplace
        </Typography>
        <button onClick={onClick}>BUY NFT</button>
      </Box>
    </Box>
  );
}

export default BuyNftBlock;
