import { useState, ReactNode, createContext, FC, useEffect, useMemo } from 'react';
import { ConfigType, MemeFirestoreType, MemeType, RemoteConfig, VotingContextType } from './VotingProvider.types';
import { Duration, intervalToDuration, differenceInWeeks, differenceInDays } from 'date-fns';
import { firebaseApp, firestoreDb } from '../../config/firebase';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { doc, getDoc, collection, DocumentData, DocumentSnapshot, increment, updateDoc } from 'firebase/firestore';

export const VotingContext = createContext<VotingContextType | null>(null);

export const VotingProvider: FC<{ config: ConfigType; children: ReactNode }> = ({ config, children }) => {
  const [seasonNumber, setSeasonNumber] = useState<number | null>(null);
  const [seasonEndDate, setSeasonEndDate] = useState<number | null>(null);
  const [seasonWeeks, setSeasonWeeks] = useState<number | null>(null);
  const [seasonDays, setSeasonDays] = useState<number | null>(null);
  const [isVotingOpen, setVotingOpen] = useState(false);
  const [isCounterIncreased, setCounterIncreased] = useState(false);
  const [timeLeft, setTimeLeft] = useState<Duration | null>(null);

  const [currentSeasonSelectedMemeID, setCurrentSeasonSelectedMemeID] = useState<string | null>(null);
  const [allMemes, setAllMemes] = useState<MemeType[] | null | undefined>(null);
  const [votingMemes, setVotingMemes] = useState<MemeType[] | null | undefined>(null);
  const [rightMeme, setRightMeme] = useState<MemeType | null>(null);
  const [leftMeme, setLeftMeme] = useState<MemeType | null>(null);
  const [showResults, setShowResults] = useState(false);

  const handleSelectMeme = (side: 'left' | 'right') => {
    switch (side) {
      case 'left': {
        const rightMemeUrl = rightMeme?.url;
        setVotingMemes((memes) => {
          const filtered = memes?.filter((meme) => meme.url !== rightMemeUrl);
          const index = filtered?.findIndex((meme) => meme.url !== leftMeme?.url);
          if (filtered && index !== undefined) {
            setRightMeme(filtered[index]);
          }
          if (filtered?.length === 1) {
            setShowResults(true);
            incrementCounter(filtered[0].memeDocumentID);
          }
          return filtered;
        });
        break;
      }
      case 'right': {
        const leftMemeUrl = leftMeme?.url;
        setVotingMemes((memes) => {
          const filtered = memes?.filter((meme) => meme.url !== leftMemeUrl);
          const index = filtered?.findIndex((meme) => meme.url !== rightMeme?.url);
          if (filtered && index !== undefined) {
            setLeftMeme(filtered[index]);
          }
          if (filtered?.length === 1) {
            setShowResults(true);
            incrementCounter(filtered[0].memeDocumentID);
          }
          return filtered;
        });
        break;
      }
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      const remoteConfig = await getRemoteConfig(firebaseApp);
      remoteConfig.settings.minimumFetchIntervalMillis = 30000;
      await fetchAndActivate(remoteConfig);
      const config = getValue(remoteConfig, 'vote_config');
      const parsedConfig: RemoteConfig = JSON.parse(config.asString());
      setSeasonNumber(parsedConfig.season.season_number);
      const weeks = differenceInWeeks(parsedConfig.season.end_date * 1000, parsedConfig.season.start_date * 1000);
      const days = differenceInDays(parsedConfig.season.end_date * 1000, parsedConfig.season.start_date * 1000);
      setSeasonWeeks(weeks);
      setSeasonDays(days);
      setSeasonEndDate(parsedConfig.season.end_date * 1000);
      const memesRefs = parsedConfig.memes;

      try {
        const memesCollectionRef = collection(firestoreDb, 'memes');

        const promises: Promise<DocumentSnapshot<DocumentData, DocumentData>>[] = [];
        memesRefs.forEach((memeRef) => {
          const memeDoc = doc(memesCollectionRef, memeRef);
          promises.push(getDoc(memeDoc));
        });
        Promise.all(promises)
          .then((snapshots) => {
            const convertedMemes: MemeType[] = [];
            snapshots.forEach((memeSnap) => {
              if (memeSnap.exists()) {
                convertedMemes.push({ ...(memeSnap.data() as MemeFirestoreType), memeDocumentID: memeSnap.id });
              } else console.log('not found documentId', memeSnap.id);
            });
            if (convertedMemes.length >= 2) {
              setLeftMeme(convertedMemes[0]);
              setRightMeme(convertedMemes[1]);
              setVotingMemes(convertedMemes);
              setAllMemes(convertedMemes);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.log('Get firestoreDb err', err);
      }
    };
    fetchConfig();
  }, []);

  const incrementCounter = async (docID: string) => {
    window.localStorage.setItem(`season_voting${seasonNumber}`, docID);
    setCurrentSeasonSelectedMemeID(docID);
    setCounterIncreased(true);

    const memesCollectionRef = collection(firestoreDb, 'memes');
    const memeDoc = doc(memesCollectionRef, docID);
    await updateDoc(memeDoc, {
      counter: increment(1),
    });
  };

  useEffect(() => {
    if (seasonNumber !== null) {
      const currentSeasonVoitedId = window.localStorage.getItem(`season_voting${seasonNumber}`);
      if (currentSeasonVoitedId !== null) {
        setCurrentSeasonSelectedMemeID(currentSeasonVoitedId);
        setShowResults(true);
      }
    }
  }, [seasonNumber]);

  const convertedVotingResults = useMemo(() => {
    const MEMES = isCounterIncreased
      ? allMemes?.map((meme) => {
          return meme.memeDocumentID === currentSeasonSelectedMemeID
            ? {
                ...meme,
                counter: meme.counter + 1,
              }
            : meme;
        })
      : allMemes;
    const totalCount = MEMES?.reduce((acc, meme) => acc + Number(meme.counter), 0) || 0;
    const maxCountNumber =
      MEMES?.reduce((acc, meme) => {
        return meme.counter > acc ? meme.counter : acc;
      }, 0) || 0;

    if (votingMemes) {
      const result = MEMES?.map((meme) => {
        const isSelectedMeme = meme.url === currentSeasonSelectedMemeID ? 1 : 0;
        const percentage = isCounterIncreased
          ? (meme.counter + isSelectedMeme) / (totalCount / 100)
          : meme.counter / (totalCount / 100);

        return {
          ...meme,
          percentage: Number(percentage.toFixed(1)),
          size: meme.counter / maxCountNumber,
        };
      });
      return result || [];
    }
    return [];
  }, [allMemes, votingMemes, isCounterIncreased, currentSeasonSelectedMemeID]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (seasonEndDate) {
        const interval = setInterval(() => {
          const localTimezoneOffset = new Date().getTimezoneOffset();
          const localTimezoneOffsetMs = localTimezoneOffset * 60 * 1000;
          const gmtTimeMs = new Date().getTime() + localTimezoneOffsetMs;
          const gmtStartDate = new Date(gmtTimeMs);
          const gmtEmdDate = seasonEndDate + localTimezoneOffsetMs;

          const interval = intervalToDuration({
            start: gmtStartDate,
            end: gmtEmdDate,
          });
          setTimeLeft(interval);
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [seasonEndDate]);

  const value: VotingContextType = {
    seasonNumber,
    isVotingOpen,
    setVotingOpen,
    timeLeft,
    rightMeme,
    leftMeme,
    handleSelectMeme,
    votingMemes,
    allMemes,
    showResults,
    currentSeasonSelectedMemeID,
    isCounterIncreased,
    seasonWeeks,
    seasonDays,
    convertedVotingResults,
  };

  return <VotingContext.Provider value={value}>{children}</VotingContext.Provider>;
};
