import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Box, useMediaQuery, Typography, Dialog } from '@mui/material';
import { keyframes } from '@emotion/react';
import Lottie from 'lottie-react';

import { useVotingState } from '../../providers/GameProvider/VotingProvider.hooks';
import VotingResults from './components/VotingResults';
import VotingFooter from './components/VotingFooter';
import VotingTimeLeft from './components/VotingTimeLeft';
import Counter from './components/Counter';

import close from '../../assets/images/voting/close.svg';
import close_mob from '../../assets/images/voting/close_mob.svg';
import mob_hands_left from '../../assets/images/voting/mob_hands_left.svg';
import mob_hands_right from '../../assets/images/voting/mob_hands_right.svg';

import background from '../../assets/images/voting/backgrund_pattern.webp';
import meme_decor_left from '../../assets/images/voting/meme_left_decoration.webp';
import meme_decor_right from '../../assets/images/voting/meme_right_decoration.webp';
import meme_stars_left from '../../assets/images/voting/stars_hover_left.webp';
import meme_stars_right from '../../assets/images/voting/stars_hover_right.webp';
import meme_hand_left from '../../assets/images/voting/hand_hover_left.webp';
import meme_hand_right from '../../assets/images/voting/hand_hover_right.webp';
import meme_left_cross from '../../assets/images/voting/meme_left_cross.svg';
import meme_right_cross from '../../assets/images/voting/meme_right_cross.svg';
import loader_animation from '../../assets/json/Loader.json';
import BuyNftBlock from './components/BuyNftBlock';
import { APP_LINKS } from '../../utils/constants';

function Voting({
  setComingSoonOpen,
}: {
  setComingSoonOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const {
    seasonNumber,
    leftMeme,
    rightMeme,
    handleSelectMeme,
    showResults,
    isVotingOpen,
    setVotingOpen,
  } = useVotingState();
  const [hoverOn, setHoverOn] = useState<'left' | 'right' | null>(null);
  const [isBlockUi, setBlockUi] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isRightImageLoaded, setRightImageLoaded] = useState(false);
  const [isLeftImageLoaded, setLeftImageLoaded] = useState(false);

  useEffect(() => {
    if (rightMeme && leftMeme) {
      const imgRight = new Image();
      const imgLeft = new Image();
      imgRight.src = rightMeme.url;
      imgLeft.src = leftMeme.url;
      imgRight.onload = () => setRightImageLoaded(true);
      imgLeft.onload = () => setLeftImageLoaded(true);
    }
  }, [rightMeme, leftMeme]);

  useLayoutEffect(() => {
    function updateSize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const sceneScale = useMemo(() => {
    const magicNumber = 393;
    const sceneScale = innerWidth / magicNumber;
    const result = innerWidth > magicNumber ? innerWidth - magicNumber : 0;
    return {
      margin: result / sceneScale,
      scale: sceneScale,
    };
  }, [innerWidth]);

  const keyframesIn = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
  `;
  const keyframesOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  `;
  const [leftFade, setLeftFade] = useState(false);
  const [rightFade, setRightFade] = useState(false);

  const handleLeftAnimationEnd = () => {
    if (leftFade) {
      handleSelectMeme('right');
      setLeftFade(false);
      setHoverOn(null);
    } else {
      setBlockUi(false);
    }
  };

  const handleRightAnimationEnd = () => {
    if (rightFade) {
      handleSelectMeme('left');
      setRightFade(false);
      setHoverOn(null);
    } else {
      setBlockUi(false);
    }
  };

  return (
    <Dialog fullScreen open={isVotingOpen}>
      <Box
        sx={{
          padding: isMobile ? '' : '40px 40px 0',
          height: '100%',
          overflowX: 'hidden',
          position: 'relative',
        }}
      >
        <img
          style={{
            position: 'absolute',
            top: isMobile ? '20px' : '0px',
            right: isMobile ? '20px' : '0px',
            cursor: 'pointer',
          }}
          src={isMobile ? close_mob : close}
          alt="close"
          onClick={() => {
            setVotingOpen(false);
          }}
        ></img>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              borderRadius: isMobile ? undefined : '60px',
              background:
                'linear-gradient(180deg, #DCD8F3 0%, #D3DFF6 50.01%, #D4EBFB 100%)',
              height: '100%',
            }}
          >
            <Box
              sx={{
                borderRadius: isMobile ? undefined : '60px',
                background: isMobile ? undefined : `url(${background})`,
                backgroundSize: '3038px 1034px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '15px',
                  marginTop: isMobile ? '30px' : '60px',
                }}
              >
                <Box
                  sx={{
                    borderRadius: '28px',
                    background: '#A19FDF',
                    width: 'max-content',
                    padding: isMobile ? '12px 30px' : '16px 40px',
                    fontFamily: 'Lilita400',
                    color: '#fff',
                    fontSize: isMobile ? '16px' : '20px',
                    lineHeight: 'normal',
                    textAlign: 'center',
                  }}
                >
                  Season {seasonNumber}
                </Box>
                <Typography
                  sx={{
                    fontFamily: 'Lilita400',
                    fontSize: isMobile ? '36px' : '56px',
                    lineHeight: 'normal',
                    textAlign: 'center',
                    margin: '0 20px',
                  }}
                >
                  {showResults
                    ? 'You rocked that vote! '
                    : 'Every vote counts'}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins400',
                    fontSize: isMobile ? '20px' : '24px',
                    lineHeight: 'normal',
                    textAlign: 'center',
                    maxWidth: '804px',
                    padding: '0 20px',
                  }}
                >
                  {showResults
                    ? `Now, don't sleep on grabbin' some $TMEME to enter our $1000-worth prize pool draw at the end of this season.`
                    : 'Choose one of the two memes that you like better. The top-voted memes will be transformed into unique NFTs, minted and traded on a dedicated marketplace!'}
                </Typography>
              </Box>
              {showResults ? (
                <>
                  <VotingResults />
                  <BuyNftBlock
                    isMobile={isMobile}
                    onClick={() => {
                      window.open(APP_LINKS.MAGIC_EDEN, '_blank');
                    }}
                  />
                </>
              ) : (
                <Box>
                  <Box
                    sx={{
                      userSelect: 'none',
                      pointerEvents: isBlockUi ? 'none' : undefined,
                      flexGrow: 1,
                      padding: isMobile ? undefined : '60px 0 0',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      margin: isMobile
                        ? `${sceneScale.margin}px 0px`
                        : undefined,
                      transform: isMobile
                        ? `scale(${sceneScale.scale})`
                        : undefined,
                      '& .starsDecoration': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '460px',
                      },
                      '& .handDecorationLeft': {
                        position: 'absolute',
                        top: '-250px',
                        right: '390px',
                        width: '1200px',
                      },
                      '& .handDecorationRight': {
                        position: 'absolute',
                        top: '-250px',
                        left: '390px',
                        width: '1200px',
                      },
                      '& .memeCrossLeft': {
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: isMobile ? '230px' : '460px',
                        zIndex: 9,
                        display: leftFade ? 'block' : 'none',
                      },
                      '& .memeCrossRight': {
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: isMobile ? '230px' : '460px',
                        zIndex: 9,
                        display: rightFade ? 'block' : 'none',
                      },

                      '& .hoverEffect': {
                        display: isMobile ? 'none' : undefined,
                        opacity: 0,
                        transition: 'all 0.3s ease',
                      },
                      '& :hover': {
                        '& .hoverEffect': {
                          opacity: 1,
                        },
                      },
                      '& .memeFadeIn': {
                        animation: `${keyframesIn} 1s linear forwards`,
                      },
                      '& .memeFadeOut': {
                        animation: `${keyframesOut} 1s linear forwards`,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        cursor: 'pointer',
                        height: 'max-content',
                        userSelect: 'none',
                        WebkitUserSelect: 'none',
                        WebkitTapHighlightColor: 'transparent',

                        marginTop: isMobile ? '130px' : undefined,
                        marginRight: isMobile ? '-40px' : undefined,
                      }}
                      onMouseMove={() =>
                        isMobile ? undefined : setHoverOn('left')
                      }
                      onMouseLeave={() => setHoverOn(null)}
                      onAnimationEnd={handleLeftAnimationEnd}
                      className={leftFade ? 'memeFadeIn' : 'memeFadeOut'}
                      onClick={() => {
                        setRightFade(true);
                        setBlockUi(true);
                      }}
                    >
                      <Box
                        sx={{
                          opacity: hoverOn === 'right' ? 0.5 : 1,
                          transition: 'all 0.3s ease',
                        }}
                      >
                        <img
                          width={isMobile ? '230px' : '460px'}
                          src={meme_decor_left}
                          alt="decoration"
                        ></img>
                        <img
                          className="starsDecoration hoverEffect"
                          src={meme_stars_left}
                          alt="decoration"
                        ></img>
                        <img
                          className="handDecorationLeft hoverEffect"
                          src={meme_hand_left}
                          alt="decoration"
                        ></img>
                        <img
                          className="memeCrossLeft"
                          src={meme_left_cross}
                          alt="decoration"
                        ></img>
                        <Box
                          sx={{
                            borderRadius: isMobile ? '20px' : '40px',
                            width: isMobile ? '150px' : '301px',
                            height: isMobile ? '150px' : '301px',
                            position: 'absolute',
                            top: isMobile ? '19px' : '38px',
                            right: isMobile ? '36px' : '72px',
                            overflow: 'hidden',
                            transform: 'rotate(-15deg)',
                            // backgroundImage: `url(${leftMeme?.url})`,
                            // backgroundRepeat: 'no-repeat',
                            // backgroundPosition: 'center',
                            // backgroundSize: 'contain',
                          }}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              '& img': {
                                width: '100%',
                              },
                            }}
                          >
                            {isLeftImageLoaded ? (
                              <img src={leftMeme?.url} alt="meme"></img>
                            ) : (
                              <Box
                                sx={{
                                  width: '120px',
                                  height: '120px',
                                }}
                              >
                                <Lottie
                                  animationData={loader_animation}
                                  loop={true}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {!isMobile && (
                      <Typography
                        sx={{
                          fontFamily: 'Lilita400',
                          fontSize: '48px',
                          lineHeight: 'normal',
                          marginTop: '200px',
                        }}
                      >
                        VS
                      </Typography>
                    )}

                    <Box
                      sx={{
                        position: 'relative',
                        cursor: 'pointer',
                        height: 'max-content',
                        userSelect: 'none',
                        WebkitUserSelect: 'none',
                        WebkitTapHighlightColor: 'transparent',
                      }}
                      onMouseMove={() =>
                        isMobile ? undefined : setHoverOn('right')
                      }
                      onMouseLeave={() => setHoverOn(null)}
                      onAnimationEnd={handleRightAnimationEnd}
                      className={rightFade ? 'memeFadeIn' : 'memeFadeOut'}
                      onClick={() => {
                        setLeftFade(true);
                        setBlockUi(true);
                      }}
                    >
                      <Box
                        sx={{
                          transition: 'all 0.3s ease',
                          opacity: hoverOn === 'left' ? 0.5 : 1,
                        }}
                      >
                        <img
                          width={isMobile ? '230px' : '460px'}
                          src={meme_decor_right}
                          alt="decoration"
                        ></img>
                        <img
                          className="starsDecoration hoverEffect"
                          src={meme_stars_right}
                          alt="decoration"
                        ></img>
                        <img
                          className="handDecorationRight hoverEffect"
                          src={meme_hand_right}
                          alt="decoration"
                        ></img>
                        <img
                          className="memeCrossRight"
                          src={meme_right_cross}
                          alt="decoration"
                        ></img>

                        <Box
                          sx={{
                            borderRadius: isMobile ? '20px' : '40px',
                            width: isMobile ? '150px' : '301px',
                            height: isMobile ? '150px' : '301px',
                            position: 'absolute',
                            top: isMobile ? '19px' : '38px',
                            left: isMobile ? '36px' : '72px',
                            overflow: 'hidden',
                            transform: 'rotate(15deg)',
                            // backgroundImage: `url(${rightMeme?.url})`,
                            // backgroundRepeat: 'no-repeat',
                            // backgroundPosition: 'center',
                            // backgroundSize: 'contain',
                          }}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              '& img': {
                                width: '100%',
                              },
                            }}
                          >
                            {isRightImageLoaded ? (
                              <img src={rightMeme?.url} alt="meme"></img>
                            ) : (
                              <Box
                                sx={{
                                  width: '120px',
                                  height: '120px',
                                }}
                              >
                                <Lottie
                                  animationData={loader_animation}
                                  loop={true}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {isMobile && (
                      <>
                        <img
                          style={{
                            position: 'absolute',
                            pointerEvents: 'none',
                          }}
                          className={rightFade ? 'memeFadeIn' : 'memeFadeOut'}
                          src={mob_hands_left}
                          alt="decor"
                        ></img>
                        <img
                          style={{
                            position: 'absolute',
                            pointerEvents: 'none',
                          }}
                          className={leftFade ? 'memeFadeIn' : 'memeFadeOut'}
                          src={mob_hands_right}
                          alt="decor"
                        ></img>
                      </>
                    )}
                  </Box>
                  <Counter></Counter>
                </Box>
              )}
              <VotingTimeLeft showResults={showResults}></VotingTimeLeft>
            </Box>
          </Box>
          <VotingFooter setComingSoonOpen={setComingSoonOpen}></VotingFooter>
        </Box>
      </Box>
    </Dialog>
  );
}

export default Voting;
