import React, { Dispatch, SetStateAction } from 'react';
import { Box, Typography } from '@mui/material';

import { SOCIALS } from '../../Header';

import x_svg from '../../../assets/images/voting/socials/x.svg';
import tg_svg from '../../../assets/images/voting/socials/tg.svg';

function VotingFooter({ setComingSoonOpen }: { setComingSoonOpen: Dispatch<SetStateAction<boolean>> }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        margin: '60px 0',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '15px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          '& img': {
            cursor: 'pointer',
          },
        }}
      >
        <img
          onClick={() => {
            if (SOCIALS[0].link !== '') {
              window.open(SOCIALS[0].link, '_blank');
            } else {
              setComingSoonOpen(true);
            }
          }}
          src={x_svg}
          alt="x"
        ></img>
        <img
          onClick={() => {
            if (SOCIALS[1].link !== '') {
              window.open(SOCIALS[1].link, '_blank');
            } else {
              setComingSoonOpen(true);
            }
          }}
          src={tg_svg}
          alt="telegram"
        ></img>
      </Box>
      <Typography
        sx={{
          fontFamily: 'Poppins400',
          fontSize: '20px',
          lineHeight: 'normal',
          textAlign: 'center',
          maxWidth: '392px',
        }}
      >
        Follow the results and season winners on our social networks
      </Typography>
    </Box>
  );
}

export default VotingFooter;
