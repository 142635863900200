import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, useMediaQuery, Slide, Typography } from '@mui/material';

import logo from '../assets/images/header/logo.webp';
import x from '../assets/images/header/social/x.svg';
import tg from '../assets/images/header/social/tg.svg';
import cm from '../assets/images/header/social/cm.svg';
import cg from '../assets/images/header/social/cg.svg';
import rd from '../assets/images/header/social/rd.svg';
import dx from '../assets/images/header/social/dex.webp';
import menu from '../assets/images/header/menu.svg';
import close from '../assets/images/header/close.svg';
import arrow from '../assets/images/header/arrow.svg';
import { APP_LINKS } from '../utils/constants';

export const SOCIALS = [
  { picture: x, name: 'Twitter', link: APP_LINKS.TWITTER },
  { picture: tg, name: 'Telegram', link: APP_LINKS.TELEGRAM },
  { picture: cm, name: 'CoinMarketCap', link: APP_LINKS.COIN_MARKET_CAP },
  { picture: cg, name: 'CoinGecko', link: APP_LINKS.COIN_GECKO },
  { picture: dx, name: 'DEXTools', link: APP_LINKS.DEX_TOOLS },
  { picture: rd, name: 'Raydium', link: APP_LINKS.RAYDIUM },
];

function Header({
  setComingSoonOpen,
  isMenuOpen,
  setMenuOpen,
}: {
  isMenuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  setComingSoonOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [offset, setOffset] = useState(0);
  const [showHeader, setShowHeader] = useState(false);
  const isMobile = useMediaQuery('(max-width:1000px)');

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    setShowHeader(offset > 100);
  }, [offset]);

  return (
    <>
      <Box
        sx={{
          background: isMenuOpen ? 'transparent' : '#00000010',
          height: '80px',
          position: 'fixed',
          width: '100%',
          top: 0,
          zIndex: 999,
          willChange: 'transform',
          transform:
            showHeader || isMenuOpen ? 'translateY(0px)' : 'translateY(-80px)',
        }}
      >
        <Box
          sx={{
            width: '100%',
            background: 'white',
            height: '70px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: '1216px',
              width: '100%',
              padding: '0 20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
              }}
            >
              <img width="85px" height="50px" src={logo} alt="logo"></img>
              {!isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    '& .social': {
                      width: '50px',
                      height: '50px',
                      background: 'black',
                      borderRadius: '50%',
                      cursor: 'pointer',
                    },
                  }}
                >
                  {SOCIALS.map((social) => (
                    <Box
                      key={social.name}
                      className="social"
                      onClick={() => {
                        if (social.link !== '') {
                          window.open(social.link, '_blank');
                        } else {
                          setComingSoonOpen(true);
                        }
                      }}
                    >
                      <img
                        style={{ height: 'inherit' }}
                        src={social.picture}
                        alt={social.name}
                      ></img>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                userSelect: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                '& button': {
                  background: '#FFBB00',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50px',
                  fontFamily: 'Lilita400',
                  fontSize: '20px',
                  textTransform: 'uppercase',
                  minWidth: '180px',
                  padding: isMobile ? '13px 26px' : '13px 13px',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                  },
                  '&:focus-visible': {
                    outline: 0,
                  },
                },
              }}
            >
              {!isMenuOpen && (
                <>
                  <button
                    onClick={() => {
                      if (APP_LINKS.BUY_COIN !== '') {
                        window.open(APP_LINKS.BUY_COIN, '_blank');
                      } else {
                        setComingSoonOpen(true);
                      }
                    }}
                  >
                    Buy now
                  </button>
                </>
              )}
              {isMobile && (
                <Box
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setMenuOpen((prev: boolean) => !prev)}
                >
                  {isMenuOpen ? (
                    <img src={close} alt="close"></img>
                  ) : (
                    <img src={menu} alt="menu"></img>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Slide in={isMenuOpen}>
        <Box
          sx={{
            borderTop: '1px solid #EAEAEA',
            height: '100vh',
            background: 'white',
            top: 70,
            position: 'fixed',
            width: '100%',
            zIndex: 999,
          }}
        >
          <Box
            sx={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              '& .wrapper': {
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                '& div:not(.socialImage)': {
                  display: 'flex',
                  gap: '15px',
                  alignItems: 'center',
                  '& p': {
                    fontSize: '28px',
                    fontFamily: 'Lilita400',
                  },
                },
                '& .socialImage': {
                  width: '50px',
                  height: '50px',
                  background: 'black',
                  borderRadius: '50%',
                },
              },
            }}
          >
            {SOCIALS.map((social) => (
              <Box
                className="wrapper"
                key={social.name}
                onClick={() => {
                  if (social.link !== '') {
                    window.open(social.link, '_blank');
                  } else {
                    setComingSoonOpen(true);
                  }
                }}
              >
                <Box>
                  <Box className="socialImage">
                    <img
                      style={{ height: 'inherit' }}
                      src={social.picture}
                      alt={social.name}
                    ></img>
                  </Box>
                  <Typography>{social.name}</Typography>
                </Box>
                <img src={arrow} alt="arrow"></img>
              </Box>
            ))}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                '& button': {
                  background: '#FFBB00',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50px',
                  fontFamily: 'Lilita400',
                  fontSize: '24px',
                  textTransform: 'uppercase',
                  width: '100%',
                  height: '78px',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  marginTop: '5px',
                  '&:focus-visible': {
                    outline: 0,
                  },
                },
              }}
            >
              <button
                onClick={() => {
                  if (APP_LINKS.BUY_COIN !== '') {
                    window.open(APP_LINKS.BUY_COIN, '_blank');
                  } else {
                    setComingSoonOpen(true);
                  }
                }}
              >
                Buy now
              </button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </>
  );
}

export default Header;
