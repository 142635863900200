import React, { Dispatch, SetStateAction } from 'react';
import { Box, useMediaQuery, Slide, Typography } from '@mui/material';

import close from '../assets/images/header/close.svg';
import background from '../assets/images/comingSoon/background.svg';
import decor from '../assets/images/comingSoon/decor.svg';

function ComingSoon({
  isComingSoonOpen,
  setComingSoonOpen,
}: {
  isComingSoonOpen: boolean;
  setComingSoonOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: '#000000',
          opacity: isComingSoonOpen ? 0.3 : 0,
          visibility: isComingSoonOpen ? 'visible' : 'hidden',
          transition: 'all 0.3s ease',
          zIndex: 1999,
        }}
      ></Box>
      <Slide in={isComingSoonOpen}>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            zIndex: 2999,
          }}
        >
          <Box
            sx={{
              width: '100%',
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '800px',
                borderRadius: '40px',
                background: 'white',
                position: 'relative',
                padding: isMobile ? '30px 20px' : '30px 30px 30px 60px',

                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'row',
                alignItems: isMobile ? 'center' : undefined,
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  top: '30px',
                  right: '30px',
                  cursor: 'pointer',
                }}
                src={close}
                alt="close"
                onClick={() => setComingSoonOpen(false)}
              ></img>
              {!isMobile && (
                <>
                  <Box
                    sx={{
                      '&::before': {
                        background: 'black',
                        position: 'absolute',
                        content: '""',
                        display: 'block',
                        width: '2px',
                        height: '100vh',
                        bottom: '432px',
                        right: '65px',
                      },
                    }}
                  >
                    <img
                      style={{
                        position: 'absolute',
                        top: '-20px',
                        right: '50px',
                      }}
                      src={decor}
                      alt="decor"
                    ></img>
                  </Box>
                  <Box
                    sx={{
                      '&::before': {
                        background: 'black',
                        position: 'absolute',
                        content: '""',
                        display: 'block',
                        width: '2px',
                        height: '100vh',
                        bottom: '432px',
                        left: '65px',
                      },
                    }}
                  >
                    <img
                      style={{
                        position: 'absolute',
                        top: '-20px',
                        left: '50px',
                      }}
                      src={decor}
                      alt="decor"
                    ></img>
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: isMobile ? '10px' : '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Lilita400',
                    fontSize: isMobile ? '36px' : '56px',
                    lineHeight: 'normal',
                    textAlign: isMobile ? 'center' : undefined,
                    maxWidth: isMobile ? '221px' : undefined,
                    marginTop: isMobile ? '10px' : undefined,
                  }}
                >
                  Coming soooooooooon
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins400',
                    fontSize: isMobile ? '20px' : '24px',
                    lineHeight: 'normal',
                    textAlign: isMobile ? 'center' : undefined,
                    maxWidth: '400px',
                  }}
                >
                  Our new super meme token is already flying to marketplaces, stay in touch!
                </Typography>
              </Box>
              <img
                style={{
                  maxWidth: isMobile ? '200px' : undefined,
                }}
                src={background}
                alt="background"
              ></img>
            </Box>
          </Box>
        </Box>
      </Slide>
    </>
  );
}

export default ComingSoon;
