import { VotingContext } from './VotingProvider';
import React from 'react';

export function useVotingState() {
  const context = React.useContext(VotingContext);

  if (!context) {
    throw new Error('use only context provider!');
  }

  return context;
}
